.volume_discount_container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 10px; */
}

.preview_welcome_container {
    margin: auto;
    /* padding: 10px; */
}

.preview_right_section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 20px;
  width: 100%;
  margin-bottom: 20px;
}
.mobile_preview{
  display: flex;
  justify-content: center;
}

.volume_offer_product{
    overflow-y: auto;
    padding-right: 5px;
    width:100%;
    max-height: 360px;
    font-family: sans-serif;
}
.volume_discount_ai{
  display: flex;
  align-items: center;
  gap: 3px;
  padding-left: 20px;
  /* padding-top: 14px; */
  /* padding-bottom: 10px; */
}
.volume_discount_ai img{
  height: 25px;
}
.volume_discount_ai p{
  font-size: 12px;
  margin: 0;
  background: var(--Gemini-Liner, linear-gradient(91deg, #9168C0 27.6%, #5684D1 53.09%, #1BA1E3 83.46%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
::-webkit-scrollbar {
    width: 4px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }