/* Slider.css */

.slider-container {
  position: relative;
  /* overflow-x: hidden; */
}

.slick-dots {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
}

.custom-next {
  position: absolute;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
}

.custom-prevs {
  position: absolute;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
}



.slick-thumb {
  margin-top: 10px;
}

.thumbnail-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 20px;
}

.thumbnail-item {
  cursor: pointer;
  margin: 0 10px;
  opacity: 0.6;
  position: relative;
  transition: opacity 0.3s ease;
}

.thumbnail-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(117, 112, 112, 0.5);
  opacity: 0.6;
  transition: opacity 0.3s ease;
}

.thumbnail-item:hover::before,
.active::before {
  opacity: 0;
}

.thumbnail-item:hover,
.active {
  opacity: 1;
}

.img_box_shadow {
  border-radius: 5.103px;
  background: lightgray 50% / cover no-repeat;
  box-shadow: 0px 2.552px 2.552px 0px rgba(0, 0, 0, 0.25);
}

.mobile-slider-layout {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 25px;
}

.mobile-slider-layout .main-slider {
  flex-grow: 1;
  width: 70%;
}

.mobile-thumbnail {
  width: 30%;
  margin: 0;
}

.mobile-thumbnail .slick-list {
  height: 200px !important;
}

.thumbnail-auto-width {
  width: auto !important;
}