.Spinwheel{
    display: flex;
    align-items: center;
    /* background-color: lightcoral !important; */
    box-shadow: none !important;
    padding: 0 20px;
    justify-content: space-between;
    /* gap: 3%; */
    margin-top: 20px;
}

.emailinput{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.spintitle{
    color: white;
}
.spintowin{
    font-size: 70px;
    font-weight: 600;
    color: white;
    text-align: left;
}

.spinsubtitle{
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 7px !important;
    text-align: left;
    line-height: 30px;
    color: white;
}
.congratstitle{
    font-size: 40px;
    line-height: 46px;
    text-align: left;
    font-weight: 700;
    color: white;
}
.congratssubtitle{
    font-size: 18px;
    color: white;
    font-weight: 600;
    margin-top: 7px;
}
.code{
    color: black;
    font-size: 35px;
    text-align: center;
    font-weight: 600;
    background-color: white;
}
.emailstyle{
    padding: 8px;
    border: none;
    outline: none;
    border-radius: 6px;
}
#canvas {
    /* margin: auto;      */
    /* display: block;  */
  }
.infodata{
    margin-left: 20px;
    margin-top: 10px;
    color: white;
}
.tryluck{
    background-color: rgb(225, 34, 34);
    font-weight: 800;
    margin-top: 10px;
    width: 100%;
    padding: 8px;
    border: none;
    outline: none;
    border-radius: 6px;
    color: white;
}
.closereward{
    background-color: rgb(225, 34, 34);
    font-weight: 800;
    margin-top: 10px;
    width: 100%;
    padding: 8px;
    border: none;
    outline: none;
    border-radius: 6px;
    color: white;
}