.welcome_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.preview_welcome_container {
    margin: auto;
}

.preview_left_section {
    width: 50%;
}

.preview_image_section {
    display: flex;
    height: 100%;
}

.preview_right_section {
    width: 50%;
}

.main_container {
    background-color: var(--primary-bg);
    height: 83vh;
    width: 100%;
    max-Height: calc(100% - 52px);
    overflow-y: auto;
}


.preview_right_section {
    width: 50%;
}

.textcolor{
    color: #585858;
    font-size: 12px;
    font-weight: 400;
}

.pricetextcolor{
    color: #403331;
    font-weight: 500;
    font-size: 12px;
}
.totalPrice{
    font-size: 16px;
    font-weight: 600;
}
.shippingtotal{
    display: flex;
    align-items: center;
    justify-content: space-between;
}



.mobile_preview{
    width: 100%; 
}

.mobile_preview_1 {
    width: 100%; 
    display: flex;
    justify-content: center;
}

.bg{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
}

.welcomemobile{
    position: absolute;
    top: calc(50% - 240px);
    left: 50%;
    width: 340px !important;
    height: 550px !important;
    z-index: 10;
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
.tabbutton{
    position: absolute;
    top: calc(50% - 256px);
    left: 50%;
    width: 357px !important;
    height: 546px !important;
    z-index: 10;
    transform: translateX(-50%);
}
.hightlight_email_bg{
    background: linear-gradient(90deg, #9168C0 57.62%, #5684D1 68.89%, #1BA1E3 82.34%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-decoration-line: underline;
}
@media (max-width:1900px) and (min-width:1700px) {
    .tabbutton{
        top: calc(50% - 219px);
    }
}
@media (max-width:1700px) and (min-width:1500px) {
    .tabbutton{
        top: calc(50% - 198px);
    }
}
@media (max-width:1600px) and (min-width:1300px) {
    .tabbutton{
        top: calc(50% - 163px);
    }
}
@media (max-width:1400px) and (min-width:1000px) {
    .tabbutton{
        top: calc(50% - 135px);
    }
}