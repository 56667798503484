.prodctText {
  /* text-transform: uppercase; */
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  color: black;
  margin-bottom: 8px
}

.mobile-prodctText {
  font-size: 12px !important;
}

.viewproduct {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: black;
}

.productbutton {
  display: flex;
  width: 120px;
  align-items: center;
  padding: 8px 11px;
  background-color: rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  gap: 8px;
  -webkit-transition: background-color 0.1s ease-in;
  -moz-transition: background-color 0.1s ease-in;
  -o-transition: background-color 0.1s ease-in;
  transition: background-color 0.1s ease-in;
  cursor: pointer;
}

.infodetails {
  background: #ffffff;
  box-shadow: 10px 18px 33px 20px rgba(13, 11, 11, 0.12);
  border-radius: 4px;
  position: absolute;
  top: 50px;
  right: 0px;
  min-height: 40px;
  padding: 16px;
  font-size: 14px;
  width: 233px;
  transition: opacity 0.33s ease-in-out;
}

.verification {
  display: flex;
  padding: 2px 10px;
  align-items: center;
  gap: 8px;
  background: var(--Success-Green-50, #ecfdf5);
  width: fit-content;
  border-radius: 8px;
}

.purchase {
  color: var(--Success-Green-900, #064e3b);
  font-size: 12px;
  font-weight: 600;
}

.satisfied {
  color: var(--2, #525252);
  font-size: 16.093px;
  font-weight: 400;
}

.product {
  color: #000;
  font-size: 18px;
  font-weight: 600;
}

.verificationCardContainer{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
  height: 100%;
}