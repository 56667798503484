.prodctText{
    /* text-transform: uppercase; */
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    color: black;
    margin-bottom: 8px
}
.viewproduct{
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: black;
}
.productbutton{
    display: flex;
    width:120px;
    align-items: center;
    padding: 8px 11px;
    background-color: rgba(0, 0, 0, 0.05);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    gap:8px;
    -webkit-transition: background-color 0.1s ease-in;
    -moz-transition: background-color 0.1s ease-in;
    -o-transition: background-color 0.1s ease-in;
    transition: background-color 0.1s ease-in;
    cursor: pointer;
}

.infodetails{
    background: #ffffff;
    box-shadow: 10px 18px 33px 20px rgba(13, 11, 11, 0.12);
    border-radius: 4px;
    position: absolute;
    top: 50px;
    right: 0px;
    min-height: 40px;
    padding: 16px;
    font-size: 14px;
    width: 233px;
    transition: opacity 0.33s ease-in-out;
}
.buttonbg{
    display: flex;
    padding: 8.411px 7.943px;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    gap: 4.673px;
    align-self: stretch;
    border-radius: 3.189px;
    background: var(--Button-style-light, linear-gradient(180deg, rgba(0, 0, 0, 0.58) 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(130deg, #5832BE 11.54%, #2F2144 68.39%, #181718 125.23%));
}

.buttontext{
    color: #FFF;
    background: transparent;
    border: none;
    text-align: center;
    font-family: "Trebuchet MS";
    font-size: 15.313px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.293px;
    /* text-transform: uppercase; */
    cursor: pointer;

}
.cliamedtext{
    color: var(--Success-Success200, #15B097);
    font-family: "Trebuchet MS";
    font-size: 15.35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 5px;
}
.mobile-cliamedtext {
 font-size: 12px;
}
.subtext{
    color: var(--2, #525252);
    font-family: "Trebuchet MS";
    font-size: 17.436px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin:10px 0px;
}
.title{
    color: #000;
    font-family: "Trebuchet MS";
    font-size: 23.593px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;
}
.price{
    color: #000;
    font-family: Inter;
    font-size: 23.22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin:10px 0px;
}
.imagesection{
    border-radius: 5.103px;
    background:  lightgray 50% / cover no-repeat;
    box-shadow: 0px 2.552px 2.552px 0px rgba(0, 0, 0, 0.25);
}
.varientimg{
    width: 67.203px;
    height: 70.944px;
    flex-shrink: 0;
    border-radius: 5.103px;
    border: 0.638px solid #A4A4A4;
    background:  lightgray 50% / cover no-repeat;
    margin-top: 26px;
}
.byai{
    background: var(--Gemini-Liner, linear-gradient(91deg, #9168C0 27.6%, #5684D1 53.09%, #1BA1E3 83.46%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    /* font-family: Poppins; */
    font-size: 15.436px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 10px;
}
.off{
    color: #FFF;
    font-family: Montserrat;
    font-size: 12.899px;
    font-style: italic;
    font-weight: 900;
    line-height: normal;
}
.select_variant{
    color: var(--2, #525252);
    font-size: 12.44px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 2px;
    line-height: normal;
    margin-top: 2px;
}
.bottomLine{
    margin-bottom: 0px;
    border-bottom:1px solid #D4D4D4;
}

.preview_left_section{
    width: 100%;
    height: 100%;
    border-radius: 5.103px;
    background: lightgray 50% / cover no-repeat;
    box-shadow: 0px 2.552px 2.552px 0px rgba(0, 0, 0, 0.25);
}

.description{
    color: var(--2, #525252);
    font-family: sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}